/** @format */

import axios from 'axios';
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';
const PREFIX = 'carbon/target/';

export const saveTarget = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}addTarget`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const getTargets = async (selectedYear) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}fetchTargets?fy_start=${
        selectedYear.fy_start
      }&fy_end=${selectedYear.fy_end}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const removeTargets = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}deleteTarget/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const fetchTargetDetail = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}targetDetails/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const updateTarget = async (id, payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}updateTarget/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

////////////////////////////// Analytics //////////////////////////////

export const targetBarChart = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}analytics/barChart`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.message || 'Oops some error occured!');
  }
};

export const horzintalBarChart = async () => {
  try {
    const auth = await headers()
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}analytics/horizontalBarChart`, {
        headers: auth,
      }
    )
    return apiResponse.data
  } catch (error) {
    throw new Error(error.message || 'Oops some error occured!')
  }
}