/** @format */

import { DeleteOutline, Info } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useEffect, useState } from "react";
import theme from "../theme";
import styles from "./assetManagement.module.css";
import DeleteModal from "../../modals/DeleteModal";
import { styled } from "@mui/material/styles";
import EditIcon from "../../assets/icons/EditIcon";
import { errorToast, successToast } from "../../utils/alerts";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '24px',
  },
}));




const ScrollableTable2 = ({
  tableContent,
  tableData,
  options,
  colLength,
  actionCellPadding = 16,
  maxWidth = 220,
  cellBorder = 'none',
  addField,
  setAddField,
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
  searchQuery

}) => {
  const [assetData, setAssetData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [activeUpdate, setActiveUpdate] = useState(false)
  const [editRowId, setEditRowId] = useState(null)
  const [formData, setFormData] = useState({})
  const [formDataToUpdate, setFormDataToUpdate] = useState({})
  const [deleteDialog, setDeleteDialog ] = useState(false)
  const [deleteRowId, setDeleteRowId] = useState(null)
  // const [addNewField, setAddNewField] = useState(0);

  const serverRequest = useCallback(async () => {
    try {
      const entities = await getRequest(getRequest)
      // console.log(444, entities.data);
      setAssetData(entities.data)
      setOriginalData(entities.data)
    } catch (error) {
      errorToast(error.message)
    }
  }, [getRequest]);
  useEffect(() => {
    serverRequest();
  }, [serverRequest]);

  useEffect(() => {
    if (!addField) {
      setFormData({});
    }
  }, [addField]);

  useEffect(()=>{
    if(searchQuery){
      const searchTerm = searchQuery.toLowerCase()
      const filteredData = originalData.filter((item)=> item.asset_name?.toLowerCase().includes(searchTerm))
      setAssetData(filteredData)
    }else{
      setAssetData(originalData)
    }
  },[ originalData, searchQuery])

  // Create

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if asset_name is present
    if (!formData.asset_name || formData.asset_name.trim() === "") {
      errorToast("Asset name is required!");
      return;
    }
  
    // Check if all "High", "Medium", "Low" fields are filled in
    const missingFields = handles.some((handle) => {
      const value = formData[handle];
      console.log(handle,":",value);
      // Check if this field uses High, Medium, Low options and if it's empty
      return options.includes("High") && options.includes("Medium") && options.includes("Low") &&
             (!value || value === ""); // True if any required field is missing
    });
  
    if (missingFields) {
      errorToast("All fields are required!");
      return;
    }
  
    try {
      const res = await postRequest(formData);
      successToast(res.message);
      setAddField(false);
      serverRequest();
    } catch (error) {
      errorToast(error.message);
    }
  };
  

  // update 

  const handleEditRow = (id) => {
    setActiveUpdate(true);
    setEditRowId(id);

    const rowData = assetData.find((asset) => asset.id === id);
    console.log('Row data:', rowData);

    setFormDataToUpdate((prevData) => ({
      ...prevData,
      [id]: rowData, // Store the form data by row ID
    }));
  };
  const setUpdatePayload = async (e) => {
    e.preventDefault();

    // Use formDataToUpdate directly
    const payload = { ...formDataToUpdate[editRowId] }; // Only send data for the row being edited
    console.log('Payload to send:', payload);

    updateSubmissionHandler(payload, editRowId);
  };
  const updateSubmissionHandler = async (payload, editRowId) => {
    try {
      const res = await putRequest(payload, editRowId);
      successToast(res.message);
      setEditRowId(null);
      setFormDataToUpdate({});
      setActiveUpdate(false);
      serverRequest();
    } catch (error) {
      errorToast(error.message);
    }
  };

  // Delete
  const handleDeleteRow = async (id) => {
    try {
      const res = await deleteRequest(id);
      successToast(res.message);
      serverRequest();
    } catch (error) {
      errorToast(error.message);
    }
  } 
  const handleDeleteModalOpen = async ( id) => {
    setDeleteRowId(id)
    setDeleteDialog(true)
  }
  // =============================================================================================================
  const handles = tableContent
    .flat()
    .filter((item) => item.handle)
    .map((item) => item.handle);
  const renderSelectFields = (asset, idx) => {
    const selectFields = [];

    handles.forEach((handle, i) => {
      // Access the row-specific data from formDataToUpdate using asset.id
      const rowData = formDataToUpdate[asset.id] || {}; // Fallback to empty object if no data
      const value = rowData[handle] ?? asset[handle]; // Get value from the selected row data or the original asset

      // Determine options based on the value type
      const options =
        typeof value === 'boolean' || value === null
          ? ['N/A', 'True', 'False']
          : ['High', 'Medium', 'Low'];

      // Your original displayValue logic
      let displayValue;
      if (typeof value === 'boolean' || value === null) {
        displayValue = value === null ? 'N/A' : value ? 'True' : 'False';
      } else {
        displayValue = value;
      }

      selectFields.push(
        <TableCell
          key={`cell-${i}`}
          className={styles.tableBodyCell}
          align="center"
          sx={{ padding: '16px 16px' }}
        >
          <FormControl
            sx={{ minWidth: '150px', width: '100%', fontFamily: 'inherit' }}
          >
            <Select
              labelId={`demo-country-label-${i}`}
              id={`demo-country-${i}`}
              name={`type-${i}`}
              onChange={(e) => {
                const newValue =
                  e.target.value === 'True'
                    ? true
                    : e.target.value === 'False'
                    ? false
                    : e.target.value === 'N/A'
                    ? null
                    : e.target.value;

                // Update only the row being edited in formDataToUpdate
                setFormDataToUpdate((prevData) => ({
                  ...prevData,
                  [asset.id]: {
                    ...prevData[asset.id], // Keep existing data for other handles in this row
                    [handle]: newValue, // Update only the current handle
                  },
                }));
              }}
              size="small"
              disabled={!activeUpdate || asset.id !== editRowId} // Disable when not editing the current row
              value={displayValue} // Use your original display logic
            >
              {options.map((item) => (
                <MenuItem key={item} value={item} id={`input-dropdown-${i}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      );
    });

    return selectFields;
  };

  const renderNewRow = (rowIndex) => {
    return (
      <TableRow key={rowIndex}>
        {handles.map((handle, index) => (
          <TableCell
            key={`new-cell-${index}`}
            className={styles.tableBodyCell}
            align="center"
            sx={{ padding: '16px 16px' }}
          >
            <FormControl
              sx={{ minWidth: '150px', width: '100%', fontFamily: 'inherit' }}
            >
              <Select
                size="small"
                value={
                  // Check if the options contain boolean values (True, False, N/A)
                  options.some((item) => item === "True" || item === "False" || item === "N/A")
                    ? formData[handle] === 1
                      ? "True"
                      : formData[handle] === 0
                      ? "False"
                      : formData[handle] === null
                      ? "N/A"
                      : ""
                    : formData[handle] || ""  // Keep empty string as default for other cases
                }
                onChange={(event) => {
                  const value = event.target.value;
  
                  let updatedValue;
  
                  // If the options contain boolean values ("True", "False")
                  if (options.some((item) => item === "True" || item === "False" || item === "N/A")) {
                    updatedValue = value === "True" ? 1 : value === "False" ? 0 : value === "N/A" ? null : null;
                  } else {
                    // For other values like "Low", "Medium", "High", keep the selected value as it is
                    updatedValue = value;
                  }
  
                  // Update formData with the selected value
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    [handle]: updatedValue,
                  }));
                }}
              >
                {options?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        ))}
      </TableRow>
    );
  };
  
  
  
  

  return (
    <div className={styles.container}>
      <Stack direction="row">
        <TableContainer
          component={Paper}
          sx={{ maxWidth: '220px' }}
          className={styles.leftTable}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
                className={styles.tableHeadRow}
              >
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{ padding: `16px` }}
                ></TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{ padding: `16px` }}
                >
                  Asset Name
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {addField && (
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      size="small"
                      placeholder="Enter new asset name"
                      value={formData['asset_name'] || ''}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          asset_name: e.target.value,
                        }))
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              {assetData.map((asset) => (
                <TableRow key={asset.id}>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  {activeUpdate && editRowId === asset.id ? (
                    <TableCell className={styles.tableBodyCell}>
                      <TextField
                        size="small"
                        value={formDataToUpdate[editRowId]?.asset_name}
                        onChange={(e) => {
                          setFormDataToUpdate((prevState) => ({
                            ...prevState,
                            [editRowId]: {
                              ...prevState[editRowId], // Retain the other fields for the current row
                              asset_name: e.target.value, // Update only asset_name for the current row
                            },
                          }));
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className={styles.tableBodyCell}>
                      <Typography variant="body1" size="small">
                        {asset.asset_name}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={styles.centerTable}
        >
          <Table>
            <TableHead>
              {tableContent.map((headRowTable, index) => (
                <TableRow
                  sx={{ bgcolor: theme.palette.esgblueblack.main }}
                  className={styles.tableHeadRow}
                  key={index}
                >
                  {headRowTable.map((item, index) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={index}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        borderRight: 'none',
                      }}
                      colSpan={item.colSpan}
                      rowSpan={item.rowSpan}
                      align="center"
                    >
                      {item.title}

                      {item.tooltip && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                variant="h6"
                                className={styles.tooltipTitle}
                                mb={2}
                              >
                                Explanation
                              </Typography>
                              <Typography
                                variant="p"
                                className={styles.tooltipExplaination}
                              >
                                {item.tooltip}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <IconButton size="small">
                            <Info
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                                marginLeft: '16px',
                              }}
                            />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {addField && renderNewRow(0)}
              {assetData.map((asset, idx) => (
                <TableRow key={idx}>
                  {renderSelectFields(asset, idx).map(
                    (selectField, fieldIdx) => (
                      <React.Fragment key={fieldIdx}>
                        {selectField}
                      </React.Fragment>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          // elevation={0}
          sx={{ maxWidth: '120px' }}
          className={styles.rightTable}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
                className={styles.tableHeadRow}
              >
                <TableCell
                  className={styles.tableHeadCell}
                  align="right"
                  sx={{ padding: '16px' }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addField && (
                <TableRow>
                  <TableCell
                    className={styles.tableBodyCell}
                    sx={{
                      padding: '16px 16px',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        color: 'white',
                      }}
                      size="small"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {assetData.map((asset) => (
                <TableRow>
                  <TableCell
                    className={styles.tableBodyCell}
                    sx={{
                      padding: '16px 16px',
                    }}
                  >
                    {activeUpdate && editRowId === asset.id ? (
                      <Stack direction="row" gap={1} justifyContent="flex-end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setActiveUpdate(!activeUpdate);
                            setEditRowId(null);
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: theme.palette.esgblueblack.main }}
                          onClick={(e) => setUpdatePayload(e)}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Stack direction="row" gap={1} justifyContent="flex-end">
                        <IconButton
                          size="small"
                          onClick={() => handleEditRow(asset.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: theme.palette.esgblueblack.main }}
                          onClick={() => handleDeleteModalOpen(asset.id)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {deleteDialog && (
        <DeleteModal open={deleteDialog} handelClose={()=>{
          setDeleteDialog(false)
        }}
        label={"Are you sure you want to delete this entry?"} 
        description={"By pressing this delete button will remove this Document permanently."} 
        id={deleteRowId}
        deleteRow={handleDeleteRow}
        />
      )}
    </div>
  );
};

export default ScrollableTable2;
