/* @format */

import axios from 'axios';
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';

const PREFIX = 'carbon/asset/';

export const getPropertyList = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}reporting/fetchTypesList`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data?.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Oops some error occured!');
  }
};

export const postAddNewAsset = async (param) => {
  try {
    const auth = await headers(true);
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}reporting/addPortfolio`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (err) {
    throw new Error(err.message || 'Oops some error occured!');
  }
};

export const getAssetsList = async (page = 1) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }reporting/fetchList?search=&limit=10&page=${page}`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Oops some error occured!');
  }
};
export const getAllAssets = async ()=> {
  try {
    const auth = await headers()
    const apiResponse = await axios.get(`${config.API_URL + PREFIX}reporting/getPortfolioList`, {
      headers: auth,
    })
    return apiResponse.data
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!')
  }
}
export const postUpdateAsset = async (payload, id) => {
  try {
    const auth = await headers(true)
    const apiResponse = await axios.put(`${config.API_URL+PREFIX}reporting/updateAsset/${id}`, payload, {
      headers: auth,
    })
    return apiResponse?.data 
  } catch (error) {
    throw new Error(error.response?.data?.message || "Oops some error occured!")
  }
}
// ========================= Tenants ========================= //
export const getAssetWithCount = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}tenant/getAsset`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Oops some error occured');
  }
};
export const getTenantList = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}tenant/getTenant/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const addDoc = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}tenant/addDoc/${id}`,
      payload,
      {
        headers: {
          ...auth,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const downloadDoc = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}tenant/downloadFile/${id}`,
      {
        responseType: 'blob',
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const deleteDoc = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}tenant/deleteDoc/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const deleteTenant = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}tenant/deleteTenant`,
      {
        headers: auth,
        data: param,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const addTenant = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}tenant/addTenant/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const updateTenant = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}tenant/updateTenant/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};
export const getTenant = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}tenant/getTenantData/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Oops some error occured');
  }
};

export const deleteAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}reporting/removeAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

// ========================= Sustainability/waste ========================= //
export const getWasteAsset = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sustainability/waste/getAsset`,
      {
        headers: auth,
      }
    );
    // console.log("Assset: ", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
export const addWasteAsset = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}sustainability/waste/addAsset`,
      payload,
      {
        headers: auth,
      }
    );

    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
export const updateWasteAsset = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}sustainability/waste/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
export const deleteWasteAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}sustainability/waste/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};

// ========================= Sustainability/ghg ========================= //
export const getGhgAsset = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sustainability/ghg/getAsset`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const addGhgAsset = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}sustainability/ghg/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const deleteGhgAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}sustainability/ghg/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const updateGhgAsset = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}sustainability/ghg/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
// ========================= Sustainability/energy ========================= //
export const getEnergyAsset = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sustainability/energy/getAsset`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const addEnergyAsset = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}sustainability/energy/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const deleteEnergyAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}sustainability/energy/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const updateEnergyAsset = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}sustainability/energy/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
// ========================= Sustainability/water ========================= //
export const getWaterAsset = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sustainability/water/getAsset`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};

export const addWaterAsset = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}sustainability/water/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const deleteWaterAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}sustainability/water/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const updateWaterAsset = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}sustainability/water/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!');
  }
};
// ========================= Sustainability/efficiency measures ========================= //
export const getEfficiencyAsset = async (limit, page) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }efficiency/fetchEfficiencyMeasures?search=&limit=${limit}&page=${page}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};

export const addEfficiencyAsset = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}efficiency/addEfficiencyMeasures`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const deleteEfficiencyAsset = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}efficiency/removeEfficiencyMeasures/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
export const updateEfficiencyAsset = async (payload, id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/updateEfficiencyMeasures/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured');
  }
};
// ========================= Sustainability/building certificates ========================= //
export const getBuildingCertificates = async (limit, page) => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }sustainability/fetchCertificationsList?search=&limit=${limit}&page=${page}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some errror occured');
  }
};

export const updateBuildingCertificates = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}sustainability/updateCertifications/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const deleteBuildingCertificates = async (id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}sustainability/removeCertifications/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const addBuildingCertificates = async (payload) => {
  try {
    const auth = headers();
    const apiResponse = await axios.post(
      `${
        config.API_URL + PREFIX
      }sustainability/addSustainabilityCertifications`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};

// ============================== Asset Management Plan ============================== //

export const getAssetResilienceList = async (tab) => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/${tab}/getAssets`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error?.response?.message || 'Oops some errror occured');
  }
};
export const getEsgTarget = async () => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/esgTarget/getEsgTarget`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error?.response?.message || 'Oops some errror occured');
  }
};

export const updateEsgTarget = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/esgTarget/updateEsgTarget/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error?.response?.message || 'Oops some errror occured');
  }
};

// ========================= Asset resilience ========================= //
export const getAssetResilience = async () => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/resilience/getAssets`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const updateAssetResilience = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/resilience/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const addAssetResilience = async (payload) => {
  try {
    const auth = headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}efficiency/resilience/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const deleteAssetResilience = async (id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}efficiency/resilience/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
// ========================= Eneregy Efficiency ========================= //
export const getEnergyEfficiency = async () => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/energy/getAssets`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const updateEnergyEfficiency = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/energy/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const addEnergyEfficiency = async (payload) => {
  try {
    const auth = headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}efficiency/energy/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const deleteEnergyEfficiency = async (id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}efficiency/energy/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
// ========================= Water Efficiency ========================= //
export const getWaterEfficiency = async () => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/water/getAssets`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const updateWaterEfficiency = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/water/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const addWaterEfficiency = async (payload) => {
  try {
    const auth = headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}efficiency/water/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const deleteWaterEfficiency = async (id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}efficiency/water/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
// ========================= Water Efficiency ========================= //
export const getWasteEfficiency = async () => {
  try {
    const auth = headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}efficiency/waste/getAssets`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const updateWasteEfficiency = async (payload, id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}efficiency/waste/updateAsset/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const addWasteEfficiency = async (payload) => {
  try {
    const auth = headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}efficiency/waste/addAsset`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
export const deleteWasteEfficiency = async (id) => {
  try {
    const auth = headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}efficiency/waste/deleteAsset/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.response.message || 'Oops some error occured');
  }
};
