/** @format */

import { DeleteOutline, Info } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState, useEffect } from 'react';
import theme from '../theme';
import styles from './assetManagement.module.css';
import { styled } from '@mui/material/styles';
import EditIcon from '../../assets/icons/EditIcon';
// import { addWasteAsset } from "../../services/private/assetManagement/assetServices";
import { errorToast, successToast } from "../../utils/alerts";
import DeleteModal from "../../modals/DeleteModal";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '24px',
  },
}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
const fixedLeftHeader = [
  [
    {
      title: '',
      colSpan: 1,
      rowSpan: 4,
    },
    {
      title: 'Asset Characteristics',
      colSpan: 2,
    },
    {
      title: 'Reporting Characteristics',
      colSpan: 1,
    },
  ],
  [
    {
      title: 'Asset Name',
      colSpan: 1,
    },
    {
      title: 'Property Type',
      colSpan: 1,
    },
    {
      title: 'Reporting Year',
      colSpan: 1,
    },
  ],
];
const ScrollableTable = ({
  tableContent,
  options,
  headCellPadding = 16,
  colLength,
  actionCellPadding = 16,
  maxWidth = 220,
  cellBorder = 'none',
  fixedLeftHeaderTable = false,
  assetData,
  tab1,
  addField,
  setAddField,
  postRequest,
  deleteRequest,
  putRequest,
}) => {
  const [activeUpdate, setActiveUpdate] = useState(false)
  const [editRowId, setEditRowId] = useState(null)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [midTableData, setMidTableData] = useState([])
  const [formData, setFormData] = useState({
    asset_name: '',
    property_type: '',
    assets: midTableData,
  });

  const [midTableBatch, setMidTableBatch] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({
    asset_name: '',
    property_type: '',
    assets: midTableBatch || [],
  });

  const [formData2, setFormData2] = useState({
    name: '',
    ...midTableData,
  });
  const [formDataToUpdate, setFormDataToUpdate] = useState({
    name: '',
  });

  // create functions here
  useEffect(() => {
    if (formData.assets.length > 0) {
      submissionHandler();
    }

    //eslint-disable-next-line
  }, [formData.assets]);

  const submissionHandler = async (payload) => {
    try {
      const response = fixedLeftHeaderTable
        ? await postRequest(formData)
        : await postRequest(payload);
      successToast(response.message);
      fixedLeftHeaderTable
        ? setFormData({ asset_name: '', property_type: '', assets: [] })
        : setFormData2({ name: '', ...midTableData });
      setMidTableData([]);
      setAddField(false);
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleInputChange = (rowIndex, columnKey, value) => {
    setMidTableData((prevData) => {
      const newData = [...prevData]; // Clone the previous state
  
      if (fixedLeftHeaderTable) {
        // Validation only when fixedLeftHeaderTable is true
        if (!/^\d+$/.test(value)) {
          errorToast("Please enter a valid integer"); // Display error toast for invalid input
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnKey]: "" // Set to empty string if not an integer
          };
        } else {
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnKey]: parseInt(value, 10) // Set integer value if valid
          };
        }
      } else {
        // No validation if fixedLeftHeaderTable is false
        newData[rowIndex] = {
          ...newData[rowIndex],
          [columnKey]: value // Set the raw value directly
        };
      }
  
      return newData;
    });
  };
  
  

  // }
  const setPayload = async (e) => {
    e.preventDefault();

    let payload;

    if (fixedLeftHeaderTable) {
      if(!formData.asset_name){
        errorToast("Asset name is a required field")
        return
      }
      if(!formData.property_type){
        errorToast("Property type is a required field")
      }
      if (
        !midTableData ||
        midTableData.length < 2 ||
        midTableData.some(
          (row) =>
            !row.reporting_year || // Check if reporting_year exists
            typeof row.reporting_year !== "number" || // Ensure it's a number
            isNaN(row.reporting_year) // Check for NaN (just in case)
        )
      ) {
        errorToast("Both rows in the table must have a valid reporting year as an integer.");
        return;
      }
      handles.forEach((handle) => {
        if (!midTableData[0][handle]) {
          midTableData[0][handle] = ""; // Default to empty string or any other default value
        }
      });
    
      // Validate if all handle keys in the first object have values
      const firstRow = midTableData[0];
      const allHandlesPresent = handles.every(
        (handle) => firstRow[handle] !== "" && firstRow[handle] !== null && firstRow[handle] !== undefined
      );
    
      if (!allHandlesPresent) {
        errorToast("The first row in the table is missing required values. Please fill it out.");
        return;
      }
      payload = { ...formData, assets: midTableData };
      setFormData(payload);
    } else if(tab1) {
      if (!formData2.name) {
        errorToast("Asset name is required!");
        return; // Stop further execution if asset_name is missing
      }
      payload = { asset_name : formData2.name, ...midTableData[0]}
      console.log("payload:", payload); // Log the created payload
      setFormData2(payload)
      submissionHandler(payload)
    }else {
      // If fixedLeftHeaderTable is false, combine with midTableData[0]
      if(!formData2.asset_name){
        errorToast("Asset name is required!");
        return
      }
      payload = { name: formData2.name, ...midTableData[0] };
      console.log('payload:', payload); // Log the created payload
      setFormData2(payload);
      submissionHandler(payload);
    }
  };





  // Delete functions here 
  const handleDeleteModalOpen = async (batchId, id) => {
    fixedLeftHeaderTable? setDeleteId(batchId):setDeleteId(id)
    setDeleteDialog(true)
  }
  const handleDeleteRow = async (id) => {
    try {
      const res = fixedLeftHeaderTable ? await deleteRequest(id) : await deleteRequest(id)
      successToast(res.message)
    } catch (error) {
      errorToast(error.message);
    }
  };

  // Update functions here

  const handleEditRow = async (batchId, id) => {
    setActiveUpdate(!activeUpdate);
    fixedLeftHeaderTable ? setEditRowId(batchId) : setEditRowId(id);
    // console.log("Selectedid:", editRowId);
    console.log('assetData::::', assetData);
    if (fixedLeftHeaderTable) {
      const batchData = assetData.find((asset) => asset.batch_id === batchId);
      setSelectedBatch({
        asset_name: batchData.asset_name,
        property_type: batchData.property_type,
        assets: batchData.assets,
      });
      setMidTableBatch(batchData.assets.map((item) => ({ ...item })));
    } else {
      const rowData = assetData.find((asset) => asset.id === id);
      // console.log('rowData:', rowData);
      if (rowData) {
        // Destructure only the fields you need for formDataToUpdate
        const { name, ...otherFields } = rowData;
        console.log('other;', otherFields);
        console.log('row', rowData);
        setFormDataToUpdate({
          ...rowData,
        });
        setMidTableBatch(Object.values(otherFields));
      } else {
        console.error('Row data not found for id:', id);
      }
    }
  };
  // Make sure midTableBatch is initialized correctly with the existing batchData.assets

  const handleUpdateInputChange = (rowIndex, columnKey, value) => {
    if (!/^\d+$/.test(value)) { // Check if the value is not a valid integer
      errorToast("Please enter a valid integer"); // Display error toast
      setMidTableBatch((prevData) => {
        const newData = [...prevData];
        newData[rowIndex] = {
          ...newData[rowIndex],
          [columnKey]: "" // Set to empty string if not an integer
        };
        return newData;
      });
    } else {
      setMidTableBatch((prevData) => {
        const newData = [...prevData];
        newData[rowIndex] = {
          ...newData[rowIndex],
          [columnKey]: parseInt(value, 10) // Set integer value
        };
        return newData;
      });
    }
  };
  
  

  const setUpdatePayload = async (e) => {
    e.preventDefault();
    let payload;
    if (fixedLeftHeaderTable) {
      payload = {
        ...selectedBatch,
        assets: midTableBatch,
      };
    } else {

        // Flatten nested structures in formDataToUpdate for non-tab1
        if (!tab1) {
          // Flatten the payload and ensure it doesn't include nested structures
          const updatedData = { ...formDataToUpdate };

          // Flatten the structure by checking for nested objects and extracting their values
          Object.keys(updatedData).forEach((key) => {
            if (typeof updatedData[key] === 'object' && updatedData[key] !== null) {
              // If the value is an object, merge it into the root structure
              Object.keys(updatedData[key]).forEach((nestedKey) => {
                updatedData[`${nestedKey}`] = updatedData[key][nestedKey];
              });
    
              // Remove the nested object from the structure after merging
              delete updatedData[key];
            }
          });
    
          // Now construct the final payload with the updated data, including the name field
          payload = { name: formDataToUpdate.name, ...updatedData };

        // payload = { name: formDataToUpdate.name, ...flatData };
      } else {
        // Preserve tab1 logic as is
        payload = { ...formDataToUpdate };
      }
    }
    console.log('payload to send :', payload);

    updateSubmissionHandler(payload, editRowId);
  };
  const updateSubmissionHandler = async (payload, editRowId) => {
    try {
      const res = await putRequest(payload, editRowId);
      successToast(res.message);
      setSelectedBatch({
        asset_name: '',
        property_type: '',
        assets: [],
      });
      setEditRowId(null);
      setActiveUpdate(false);
    } catch (error) {
      errorToast(error.message);
    }
  };

  // console.log("fixed", fixedLeftHeaderTable);
  const handles = tableContent
    .flat()
    .filter((item) => item.handle)
    .map((item) => item.handle);

  const renderCells = (asset, group, idx) => {
    const selectFields = [];
    const handles = tableContent
      .flat()
      .filter((item) => item.handle)
      .map((item) => item.handle);

    handles.forEach((handle, index) => {
      // console.log(555, handle);
      const isEditing =
        activeUpdate &&
        editRowId === (fixedLeftHeaderTable ? group.batch_id : asset.id);
      // Ensure you get the right value based on the row index
      // const value = isEditing ? midTableBatch[index]?.[handle] : asset[handle]; // Get the value from midTableBatch or asset
      const currentBatchItem = formDataToUpdate || {}; // formDataToUpdate as the main data source
      const value = currentBatchItem[handle] ?? asset[handle];
      selectFields.push(
        <TableCell
          key={`cell-${index}`}
          className={styles.tableBodyCell}
          align="center"
          sx={{ padding: fixedLeftHeaderTable ? '6.6px 16px' : '16px 16px' }}
        >
          {isEditing ? (
            <FormControl
              sx={{ minWidth: '150px', width: '100%', fontFamily: 'inherit' }}
            >
              {tab1 ? (
                <TextField
                  size="small"
                  value={value}
                  onChange={(e) =>
                    setFormDataToUpdate((prevData) => {
                      // Copy the previous state
                      const updatedData = { ...prevData };

                      // Update the specific handle for the correct asset in formDataToUpdate
                      updatedData[handle] = e.target.value;

                      return updatedData; // Return the updated formDataToUpdate state
                    })
                  }
                />
              ) : (
                <TextField
                  size="small"
                  value={midTableBatch[idx]?.[handle] || ''}
                  onChange={(e) =>
                    handleUpdateInputChange(idx, handle, e.target.value)
                  }
                />
              )}
            </FormControl>
          ) : (
            <Typography sx={{ padding: '8px 0' }}>
              {asset[handle] !== undefined ? asset[handle] : 'N/A'}
            </Typography>
          )}
        </TableCell>
      );
    });

    return selectFields;
  };

  // console.log("addData:", midTableData);
  const renderSelectFields = (asset, idx) => {
    const selectFields = [];
    // console.log(555, handles);
    handles.forEach((handle, i) => {
      // Check if formDataToUpdate has data for this specific row (editRowId)
      const rowData = formDataToUpdate[asset.id] || {}; // Use asset.id to store data by row ID
      const value = rowData[handle] ?? asset[handle]; // Retrieve value from rowData or fall back to asset

      let selectedValue;
      if (value === '1') {
        selectedValue = 'True';
      } else if (value === '0') {
        selectedValue = 'False';
      } else {
        selectedValue = 'N/A'; // For null or undefined
      }

      selectFields.push(
        <TableCell
          key={`cell-${i}`}
          className={styles.tableBodyCell}
          align="center"
          sx={{ padding: fixedLeftHeaderTable ? '6.6px 16px' : '16px 16px' }}
        >
          <FormControl
            sx={{ minWidth: '150px', width: '100%', fontFamily: 'inherit' }}
          >
            <Select
              labelId={`demo-country-label-${i}`}
              id={`demo-country-${i}`}
              name={`type-${i}`}
              size="small"
              value={selectedValue} // Use selected value specific to the row
              disabled={!activeUpdate || asset.id !== editRowId}
              onChange={(e) => {
                const newValue =
                  e.target.value === 'True'
                    ? '1'
                    : e.target.value === 'False'
                    ? '0'
                    : null;

                setFormDataToUpdate((prevData) => ({
                  ...prevData,
                  [asset.id]: {
                    ...prevData[asset.id],
                    [handle]: newValue,
                  },
                }));
              }}
            >
              {options?.map((item) => (
                <MenuItem key={item} value={item} id={`input-dropdown-${i}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      );
    });

    return selectFields;
  };

  // const renderNewRow = (rowIndex) => {
  //   return (
  //     <TableRow>
  //       {handles.map((handle, index) => (

  //         <TableCell
  //           key={`new-cell-${index}`}
  //           className={styles.tableBodyCell}
  //           align="center"
  //           sx={{ padding: !fixedLeftHeaderTable ? "6.6px 16px" : "16px 16px" }}
  //         >
  //           <FormControl sx={{ minWidth: "150px", width: "100%", fontFamily: "inherit" }}>
  //             <TextField size="small" type="text" placeholder={`Enter ${handle}`} onChange={(e) => handleInputChange(rowIndex, handle, e.target.value)} value={midTableData[rowIndex]?.[handle] || ""} />
  //           </FormControl>
  //         </TableCell>
  //       ))}
  //     </TableRow>
  //   );
  // };
  const renderNewRow = (rowIndex) => {
    return (
      <TableRow>
        {handles.map((handle, index) => (
          <TableCell
            key={`new-cell-${index}`}
            className={styles.tableBodyCell}
            align="center"
            sx={{ padding: !fixedLeftHeaderTable ? '6.6px 16px' : '16px 16px' }}
          >
            <FormControl
              sx={{ minWidth: '150px', width: '100%', fontFamily: 'inherit' }}
            >
              {!fixedLeftHeaderTable && !tab1 ? (
                <Select
                  size="small"
                  value={
                    midTableData[rowIndex]?.[handle] === '1'
                      ? 'True'
                      : midTableData[rowIndex]?.[handle] === '0'
                      ? 'False'
                      : 'N/A'
                  }
                  onChange={(e) => {
                    const value =
                      e.target.value === 'True'
                        ? '1'
                        : e.target.value === 'False'
                        ? '0'
                        : null;
                    handleInputChange(rowIndex, handle, value);
                  }}
                >
                  {options?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  size="small"
                  type="text"
                  placeholder={`Enter ${handle}`}
                  onChange={(e) =>
                    handleInputChange(rowIndex, handle, e.target.value)
                  }
                  value={midTableData[rowIndex]?.[handle] || ''}
                />
              )}
            </FormControl>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <div className={styles.container}>
      <Stack direction="row">
        <TableContainer
          component={Paper}
          sx={{ maxWidth: maxWidth + 'px' }}
          className={styles.leftTable}
        >
          <Table>
            {fixedLeftHeaderTable ? (
              <TableHead>
                {fixedLeftHeader.map((itemTable, index) => (
                  <TableRow
                    sx={{ bgcolor: theme.palette.esgblueblack.main }}
                    className={styles.tableHeadRow}
                    key={index}
                  >
                    {itemTable.map((item, i) => (
                      <TableCell
                        className={styles.tableHeadCell}
                        sx={{
                          padding: `${headCellPadding}px 16px`,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          borderRight: cellBorder,
                        }}
                        key={i}
                        colSpan={item.colSpan}
                        rowSpan={item.rowSpan}
                        align="center"
                      >
                        {item.title}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{ bgcolor: theme.palette.esgblueblack.main }}
                  className={styles.tableHeadRow}
                >
                  <TableCell
                    className={styles.tableHeadCell}
                    sx={{ padding: `${headCellPadding}px 16px` }}
                  ></TableCell>
                  <TableCell
                    className={styles.tableHeadCell}
                    sx={{ padding: `${headCellPadding}px 16px` }}
                  >
                    Asset Name
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {fixedLeftHeaderTable ? (
                <>
                  {addField && (
                    <>
                      <TableRow>
                        <TableCell rowSpan={2}>
                          <Checkbox />
                        </TableCell>
                        <TableCell rowSpan={2}>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Enter new asset name"
                            value={formData.asset_name}
                            onChange={(e) =>
                              setFormData((prevState) => ({
                                ...prevState,
                                asset_name: e.target.value,
                              }))
                            }
                          />
                        </TableCell>
                        <TableCell rowSpan={2}>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Enter property type"
                            value={formData.property_type}
                            onChange={(e) =>
                              setFormData((prevState) => ({
                                ...prevState,
                                property_type: e.target.value,
                              }))
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Enter reporting year"
                            onChange={(e) =>
                              handleInputChange(
                                0,
                                'reporting_year',
                                e.target.value
                              )
                            }
                            value={midTableData[0]?.reporting_year || ''}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Enter reporting year"
                            onChange={(e) =>
                              handleInputChange(
                                1,
                                'reporting_year',
                                e.target.value
                              )
                            }
                            value={midTableData[1]?.reporting_year || ''}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              ) : (
                <>
                  {addField && (
                    <TableRow>
                      {tab1 ?(<TableCell ></TableCell>):(
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      )}
                      <TableCell>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter new asset name"
                          value={formData2.asset_name}
                          onChange={(e) =>
                            setFormData2((prevState) => ({
                              ...prevState,
                              name: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
              {fixedLeftHeaderTable ? (
                <>
                  {assetData.map((asset) => (
                    <React.Fragment key={asset.id}>
                      <TableRow>
                        <TableCell rowSpan={2}>
                          <Checkbox />
                        </TableCell>
                        <TableCell rowSpan={2}>
                          {activeUpdate && editRowId === asset.batch_id ? (
                            <TextField
                              size="small"
                              value={selectedBatch.asset_name}
                              onChange={(e) =>
                                setSelectedBatch((prevState) => ({
                                  ...prevState,
                                  asset_name: e.target.value,
                                }))
                              }
                            />
                          ) : (
                            <Typography variant="body1" size="small">
                              {asset.asset_name}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={styles.tableBodyCell}
                          rowSpan={2}
                          sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {activeUpdate && editRowId === asset.batch_id ? (
                            <TextField
                              size="small"
                              value={selectedBatch.property_type}
                              onChange={(e) =>
                                setSelectedBatch((prevState) => ({
                                  ...prevState,
                                  property_type: e.target.value,
                                }))
                              }
                            />
                          ) : (
                            <Typography variant="body1" size="small">
                              Office: {asset.property_type}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell className={styles.tableBodyCell}>
                          {activeUpdate && editRowId === asset.batch_id ? (
                            <TextField
                              size="small"
                              value={midTableBatch[0]?.reporting_year || ''}
                              onChange={(e) =>
                                handleUpdateInputChange(
                                  0,
                                  'reporting_year',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Typography variant="body1" size="small">
                              {asset.assets[0].reporting_year}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.tableBodyCell}>
                          {activeUpdate && editRowId === asset.batch_id ? (
                            <TextField
                              size="small"
                              value={midTableBatch[1]?.reporting_year || ''}
                              onChange={(e) =>
                                handleUpdateInputChange(
                                  1,
                                  'reporting_year',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Typography variant="body1" size="small">
                              {asset.assets[1].reporting_year}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  {tab1 ? (
                    <>
                      {assetData.map((asset) => (
                        <TableRow key={asset.id}>
                          {activeUpdate && editRowId === asset.id ? (
                            <TableCell className={styles.tableBodyCell}>
                            <TextField  size="small" value={formDataToUpdate.asset_name} onChange={(e)=> setFormDataToUpdate((prevState)=> ({...prevState, asset_name: e.target.value}))}/>
                          </TableCell>
                          ) : (
                            <>
                            <TableCell></TableCell>
                          <TableCell className={styles.tableBodyCell}>
                            <Typography variant="body1" size="small">{asset.asset_name}</Typography>
                          </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {assetData.map((asset) => (
                        <TableRow key={asset.id}>
                          <TableCell>
                            <Checkbox />
                          </TableCell>
                          {activeUpdate && editRowId === asset.id ? (
                            <TableCell className={styles.tableBodyCell}>
                              <TextField
                                size="small"
                                value={formDataToUpdate.name}
                                onChange={(e) =>
                                  setFormDataToUpdate((prevState) => ({
                                    ...prevState,
                                    name: e.target.value,
                                  }))
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell className={styles.tableBodyCell}>
                              <Typography variant="body1" size="small">
                                {asset.name}
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={styles.centerTable}
        >
          <Table>
            <TableHead>
              {tableContent.map((headRowTable, index) => (
                <TableRow
                  sx={{ bgcolor: theme.palette.esgblueblack.main }}
                  className={styles.tableHeadRow}
                  key={index}
                >
                  {headRowTable.map((item, index) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={index}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        borderRight: cellBorder,
                      }}
                      colSpan={item.colSpan}
                      rowSpan={item.rowSpan}
                      align="center"
                    >
                      {item.title}

                      {item.tooltip && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                variant="h6"
                                className={styles.tooltipTitle}
                                mb={2}
                              >
                                Explanation
                              </Typography>
                              <Typography
                                variant="p"
                                className={styles.tooltipExplaination}
                              >
                                {item.tooltip}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <IconButton size="small">
                            <Info
                              sx={{
                                color: 'white',
                                fontSize: '14px',
                                marginLeft: '16px',
                              }}
                            />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {fixedLeftHeaderTable ? (
                <>
                  {addField && renderNewRow(0)}
                  {addField && renderNewRow(1)}
                </>
              ) : (
                <>{addField && renderNewRow(0)}</>
              )}
              {fixedLeftHeaderTable ? (
                <>
                  {Array.isArray(assetData) &&
                    assetData.map((group, groupIndex) =>
                      group.assets.map((asset, assetIndex) => (
                        <TableRow key={`${groupIndex}-${assetIndex}`}>
                          {renderCells(asset, group, assetIndex).map(
                            (cell, cellIndex) => (
                              <React.Fragment key={cellIndex}>
                                {cell}
                              </React.Fragment>
                            )
                          )}
                        </TableRow>
                      ))
                    )}
                </>
              ) : (
                <>
                  {tab1 ? (
                    <>
                      {/* Tab 1 specific rendering logic */}
                      {assetData.map((asset, idx) => (
                        <TableRow key={idx}>
                          {/* Add Tab 1 specific cell rendering */}
                          {renderCells(asset).map((cell, cellIndex) => (
                            <React.Fragment key={cellIndex}>
                              {cell}
                            </React.Fragment>
                          ))}
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* Default rendering for other cases when tab1 is false */}
                      {Array.isArray(assetData) &&
                        assetData.map((asset, idx) => (
                          <TableRow key={idx}>
                            {renderSelectFields(asset, idx).map(
                              (selectField, fieldIdx) => (
                                <React.Fragment key={fieldIdx}>
                                  {selectField}
                                </React.Fragment>
                              )
                            )}
                          </TableRow>
                        ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          // elevation={0}
          sx={{ maxWidth: '120px' }}
          className={styles.rightTable}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
                className={styles.tableHeadRow}
              >
                <TableCell
                  className={styles.tableHeadCell}
                  align="right"
                  sx={{ padding: `${actionCellPadding}px 16px` }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addField && (
                <TableRow>
                  <TableCell
                    className={styles.tableBodyCell}
                    sx={{
                      padding: fixedLeftHeaderTable ? '60px 25px' : '16px 16px',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        color: 'white',
                      }}
                      size="small"
                      onClick={(e) => setPayload(e)}
                    >
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {assetData.map((asset) => (
                <TableRow key={asset.id}>
                  <TableCell
                    className={styles.tableBodyCell}
                    sx={{
                      padding: fixedLeftHeaderTable
                        ? '33.7px 16px'
                        : '16px 16px',
                    }}
                  >
                    {fixedLeftHeaderTable ? (
                      activeUpdate && editRowId === asset.batch_id ? (
                        <Stack
                          direction="row"
                          gap={1}
                          justifyContent="flex-end"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setActiveUpdate(!activeUpdate);
                              setEditRowId(null);
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{ color: theme.palette.esgblueblack.main }}
                            onClick={(e) => setUpdatePayload(e)}
                          >
                            <SaveIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Stack
                          direction="row"
                          gap={1}
                          justifyContent="flex-end"
                        >
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleEditRow(asset.batch_id, asset.id)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{ color: theme.palette.esgblueblack.main }}
                            onClick={() => handleDeleteModalOpen(asset.batch_id, asset.id)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Stack>
                      )
                    ) : activeUpdate && editRowId === asset.id ? (
                      <Stack direction="row" gap={1} justifyContent="flex-end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setActiveUpdate(!activeUpdate);
                            setEditRowId(null);
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: theme.palette.esgblueblack.main }}
                          onClick={(e) => setUpdatePayload(e)}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      activeUpdate && editRowId === asset.id ? (
                        <Stack direction="row" gap={1} justifyContent="flex-end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setActiveUpdate(!activeUpdate)
                              setEditRowId(null)
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{ color: theme.palette.esgblueblack.main }}
                            onClick={(e) => setUpdatePayload(e)}
                          >
                            <SaveIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Stack direction="row" gap={1} justifyContent="flex-end">
                          <IconButton
                            size="small"
                            onClick={() => handleEditRow(asset.batch_id, asset.id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{ color: theme.palette.esgblueblack.main }}
                            onClick={() => handleDeleteModalOpen(asset.batch_id, asset.id)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Stack>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {deleteDialog && (
        <DeleteModal open={deleteDialog} handelClose={()=>{
          setDeleteDialog(false)
        }}
        label={"Are you sure you want to delete this entry?"} 
        description={"By pressing this delete button will remove this Document permanently."} 
        id={deleteId}
        deleteRow={handleDeleteRow}
        />
      )}

    </div>
  );
};

export default ScrollableTable;
