/** @format */

import { Box, Typography } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
import styles from "./charts.module.css";

const HorizontalBarChart = ({ bgColor = "#f6f7fb", borderRadius = 6 , chartData}) => {
  const prepareChartData = (chartData) => {
    const categoriesSet = new Set();
    const yearsSet = new Set();
    const categoryData = {};
  
    // Aggregate all categories and years across scopes
    Object.values(chartData).forEach((scope) => {
      scope.forEach(({ category, year, emission }) => {
        categoriesSet.add(category);
        yearsSet.add(year);
        if (!categoryData[category]) {
          categoryData[category] = {};
        }
        categoryData[category][year] = emission;
      });
    });
  
    const categories = Array.from(categoriesSet);
    const years = Array.from(yearsSet).sort(); // Sort years to maintain order
    const colors = ["#6DB3B5", "#165BAA", "#AEE7E8", "#FFBB00"]; // Extend as needed
  
    const datasets = years.map((year, index) => {
      return {
        label: year,
        data: categories.map(
          (category) => categoryData[category]?.[year] || 0
        ),
        backgroundColor: colors[index % colors.length],
        barThickness: 12,
      };
    });
  
    return {
      labels: categories,
      datasets,
    };
  };
  console.log("data:", chartData);
  const data = prepareChartData(chartData);
  // const data = {
  //   labels: ["Refrigerants", "Waste", "Travel", "Scope 2 Emissions"],
  //   datasets: [
  //     {
  //       label: "2022",
  //       data: [-8000, 4000, 8000, 40000],
  //       backgroundColor: "#6DB3B5",
  //       barThickness: 12,
  //     },
  //     {
  //       label: "2025",
  //       data: [75000, -7500, 8500, 30000],
  //       backgroundColor: "#165BAA",
  //       barThickness: 12,
  //     },
  //     {
  //       label: "2030",
  //       data: [60000, 6000, -6000, 10000],
  //       backgroundColor: "#AEE7E8",
  //       barThickness: 12,
  //     },
  //   ],
  // };
  const options = {
    indexAxis: "y",
    aspectRatio: 5,
    scales: {
      y: {
        title: {
          display: true,
          color: "#577077",
          font: {
            fontFamily: "roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
        },
      },
      x: {
        ticks: {
          stepSize: 5000,
        },
        title: {
          display: true,
          color: "#577077",
          font: {
            fontFamily: "roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "middle",
        labels: {
          padding: 20,
          boxWidth: 21,
        },
      },
    },
  };
  return (
    <Box
      className={styles.horizontalBarChartContainer}
      sx={{ bgcolor: bgColor, borderRadius: borderRadius + "px" }}
    >
      <Box className={styles.titleContainer}>
        <Typography
          variant="h5"
          className={styles.chartTitle}
          sx={{ height: "67px" }}
        >
          Emissions Reduction Targets.(C02E)
        </Typography>
      </Box>
      <Box className={styles.barChart}>
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default HorizontalBarChart;
