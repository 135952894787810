/** @format */

import { Add, Remove } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './tcfdAccordion.module.css';
import { postTCFQuestionResponse } from '../../../services/private/dataVault/dataVaultService';
import { errorToast, successToast } from '../../../utils/alerts';

const TcfdAccordion = ({ data }) => {
  const [questions, setQuestions] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState({});

  // Initialize questions and expanded state when `data` changes
  useEffect(() => {
    if (data && data.length) {
      setQuestions(data);

      const initialExpandedState = data.reduce((acc, accordion) => {
        acc[accordion.id] = false;
        return acc;
      }, {});
      setExpandedAccordions(initialExpandedState);
    }
  }, [data]);

  const handleToggle = (accordionId) => {
    setExpandedAccordions((prevExpanded) => ({
      ...prevExpanded,
      [accordionId]: !prevExpanded[accordionId],
    }));
  };

  const handleInputChange = (value, id) => {
    setQuestions((cur) =>
      cur.map((item) =>
        item.id === id
          ? {
              ...item,
              answer: item.answer
                ? { ...item.answer, answer: value } // If answer object exists, update it
                : { answer: value }, // If answer is null, create a new object
            }
          : item
      )
    );
  };

  const handleResponse = async (param) => {
    const { id, answer } = param;
    try {
      const res = await postTCFQuestionResponse({
        question_id: String(id),
        answer: answer ? answer.answer : '', // Extract the answer string if it exists
      });
      // console.log("res",res.type);
      if (res.type === 'RXSUCESS'){
        successToast('Submitted successfully!');
        handleToggle(id);
      }
    } catch (error) {
      errorToast(error.message)
      //console.error('Error:', error);
    }
  };

  const handleClear = (id) => {
    setQuestions((cur) =>
      cur.map((item) =>
        item.id === id
          ? {
              ...item,
              answer: null, // Clear the answer by setting it to null
            }
          : item
      )
    );
  };

  return (
    <Stack className={styles.accordionContainer} gap={2} mt={2} pb={3}>
      {questions.map((item) => (
        <Accordion
          expanded={expandedAccordions[item.id]}
          onChange={() => handleToggle(item.id)}
          elevation={0}
          key={item.id}
          sx={{
            borderRadius: '8px',
            background: '#F6F7FB',
            padding: '12px 8px',
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton>
                {expandedAccordions[item.id] ? <Remove /> : <Add />}
              </IconButton>
            }
            aria-controls={`panel${item.id}-content`}
            id={`panel${item.id}-header`}
          >
            <Typography className={styles.summary}>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack gap={1}>
              <TextField
                label="Description"
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                value={item.answer ? item.answer.answer : ''} // Check if answer object exists
                onChange={(e) => handleInputChange(e.target.value, item.id)}
              />
              <Stack direction="row" gap={2}>
                <Button
                  variant="contained"
                  sx={{ color: '#fff' }}
                  onClick={() => handleResponse(item)}
                >
                  Save
                </Button>
                <Button variant="outlined" onClick={() => handleClear(item.id)}>
                  Clear
                </Button>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default TcfdAccordion;
